:root {
  --bg-color: #333333;
  --text-color: #ffffff;
  --button-bg: var(--bg-color);
  --button-text: #ffffff;
}

body.light-mode {
  --bg-color: #ffffff;
  --text-color: #333333;
  --button-bg: --bg-color;
  --button-text: #333333;
}

body {
  background-color: var(--bg-color);
  color: var(--text-color);
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

.App {
  text-align: center;
  padding-top: 60px;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: var(--bg-color);
  padding: 1rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

nav {
  display: flex;
  justify-content: flex-end;
}
nav button {
  background-color: var(--button-bg);
  color: var(--button-text);
  border: none;
  padding: 10px 15px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  transition: all 0.3s ease;
  position: relative;
}

nav button::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--button-text);
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease;
}

nav button:hover {
  color: var(--button-text);
}

nav button:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Then in your component CSS files */
.home-container, .meanderings-container {
  background-color: var(--bg-color);
  color: var(--text-color);
}
